import React from "react"
import {useTranslation} from "react-i18next";
import * as teamStyles from "./teamStyles";
import Thomas from '../../../images/team/Thomas.jpg';
import Ben from '../../../images/team/Ben.jpg';
import Milos from '../../../images/team/Milos.jpg';
import {Separator} from "../../Separator/separator"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import * as footerStyle from "../../Footer/footerStyle";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import $ from 'jquery';

if (typeof window !== 'undefined') {
    $(document).ready(function () {
        $(".content").hide();
        $(".show_hide_thomas").on("click", function () {
            $(this).next('.content').slideToggle(200);
        });
        $(".show_hide_milos").on("click", function () {
            $(this).next('.content').slideToggle(200);
        });
        $(".show_hide_ben").on("click", function () {
            $(this).next('.content').slideToggle(200);
        });
    });
}

export const Team = () => {
    const {t} = useTranslation();

    return (
        <div className={'team-component'} style={teamStyles.teamBlock}>
            <div>
                <p style={teamStyles.teamEnlarged}> {t('app.block.aboutUs.team')} </p>
                <p> {t('app.block.aboutUs.team.text')} </p>
            </div>
            <div className={'team-members'} style={teamStyles.teamMembers}>
                <div className={'team-member'}>
                    <img style={teamStyles.teamImage} src={Thomas} alt={'Thomas'}/>
                    <div className={'team-member-info'} style={teamStyles.teamText}>
                        <p style={teamStyles.teamName}> {t('app.block.aboutUs.team.thomas.name')} <br/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/thomas-zellerer-msc-frm-0ab8381a/"><LinkedInIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                            <a style={footerStyle.footerParagraph} href="mailto:zellerer@quantedgesolutins.com"><EmailIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                            <a style={footerStyle.footerParagraph} href="tel:+491637375340"><CallIcon sx={{ color: '#203B72', fontSize: '18px' }} /></a>
                        </p>
                        <p className={'m-0'}> {t('app.block.aboutUs.team.thomas.title')} </p>
                        <Separator />
                        <a style={teamStyles.nameColor} href="javascript:void(0)" className="show_hide_thomas" data-content="toggle-text">{t('app.block.aboutUs.team.thomas.more')}</a>
                        <div className="content">{t('app.block.aboutUs.team.thomas.biography')}</div>
                    </div>
                </div>
                <div className={'team-member'}>
                    <img style={teamStyles.teamImage} src={Milos} alt={'Milos'}/>
                    <div className={'team-member-info'} style={teamStyles.teamText}>
                        <p style={teamStyles.teamName}> {t('app.block.aboutUs.team.milos.name')} <br/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/milosmucic95/"><LinkedInIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                            <a style={footerStyle.footerParagraph} href="mailto:mucic@quantedgesolutins.com"><EmailIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                            <a style={footerStyle.footerParagraph} href="tel:+4917644209402"><CallIcon sx={{ color: '#203B72', fontSize: '18px' }} /></a>
                        </p>
                        <p className={'m-0'}> {t('app.block.aboutUs.team.milos.title')} </p>
                        <Separator />
                        <a style={teamStyles.nameColor} href="javascript:void(0)" className="show_hide_milos" data-content="toggle-text">{t('app.block.aboutUs.team.milos.more')}</a>
                        <div className="content">{t('app.block.aboutUs.team.milos.biography')}</div>
                    </div>

                </div>
                <div className={'team-member'}>
                    <img style={teamStyles.teamImage} src={Ben} alt={'Ben'}/>
                    <div className={'team-member-info'} style={teamStyles.teamText}>
                        <p style={teamStyles.teamName}> {t('app.block.aboutUs.team.ben.name')} <br/>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/benedikt-grimus-3a8049231/"><LinkedInIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                            <a style={footerStyle.footerParagraph} href="mailto:grimus@quantedgesolutins.com"><EmailIcon sx={{ color: '#203B72', fontSize: '18px', marginRight: '5px' }} /></a>
                        </p>
                        <p className={'m-0'}> {t('app.block.aboutUs.team.ben.title')} </p>
                        <Separator />
                        <a style={teamStyles.nameColor} href="javascript:void(0)" className="show_hide_ben" data-content="toggle-text">{t('app.block.aboutUs.team.ben.more')}</a>
                        <div className="content">{t('app.block.aboutUs.team.ben.biography')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}