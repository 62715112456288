// styles

export const teamBlock = {
    margin: "20px 10px 0",
    background: "rgb(252, 252, 252)",
    padding: "15px"
}
export const teamEnlarged = {
    fontSize: '22px',
    display: 'flex',
    fontWeight: '500',
    marginBottom: '2px'
}

export const teamText = {
    width: "250px",
    margin: "10px 0",
    textAlign: "center"
}

export const teamMembers = {
    display: "flex",
    margin: "20px 0",
    justifyContent: "space-between",
}
export const teamImage = {
    clipPath: "circle(50% at 50% 50%)",
    width: "250px",
    height: "250px",
}

export const teamName = {
    fontWeight: "500",
}

export const nameColor = {
    color: "#203B72",
}

const TeamStyles = () => {

}

export default TeamStyles