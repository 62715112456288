// styles

export const referenceBlock = {
    margin: "20px 10px",
    background: "rgb(252, 252, 252)",
    padding: "15px"
}
export const referenceEnlarged = {
    fontSize: '22px',
    display: 'flex',
    fontWeight: '500',
    marginBottom: '2px'
}
export const referenceImage = {
    objectFit: "cover",
    width: "100%",
    maxHeight: "200px",
    margin: "0 0 20px",
}
const ReferenceStyles = () => {

}

export default ReferenceStyles