import React, {useState} from "react"
import {useTranslation} from "react-i18next";
import * as videoStyles from "../../styles/video";
import videoEN from '../../videos/videoEN.webm';
import videoDE from '../../videos/videoDE.webm';
import videoThumbnail from '../../images/videoThumb.png';
import i18n from "../i18n/i18n";

let video = videoDE;

export const Video = () => {
    const {t} = useTranslation();

    i18n.on('languageChanged', function(lng) {
        if (lng === 'en') {
            video = videoEN
        } else {
            video = videoDE
        }
    })
    return (
        <div className={'video-component'} style={videoStyles.videoContainer}>
            <video controls key={video} poster={videoThumbnail} style={videoStyles.video}>
                <source src={video} type="video/mp4"/>
            </video>
            <div style={videoStyles.introText}>
                <p>
                    {t('app.block.video.text')}
                </p>
            </div>
        </div>
    )
}