// styles
export const service = {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
    border: "1px solid transparent",
    borderRadius: "8px",
    overflow: "hidden",
    margin: 'auto 10px',

}
export const serviceImage = {
    width: 'calc(50% + 16px)',
    height: '330px',
    objectFit: 'cover',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    zIndex: '10',
    backgroundColor: 'white',
    float: 'right',
}

export const serviceImage2 = {
    width: 'calc(50% + 17px)',
    height: '330px',
    objectFit: 'cover',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    zIndex: '10',
    backgroundColor: 'white',
    float: 'left',
}

export const serviceBlock = {
    display: 'flex',
    background: "rgb(252, 252, 252)",
}


export const serviceTextRight = {
    width: 'calc(50% - 15px)',
    margin: 'auto 15px auto 15px',
    textAlign: 'justify',
}
export const serviceTextLeft = {
    width: 'calc(50% - 15px)',
    margin: 'auto 15px auto 15px',
    textAlign: 'justify',
}
const ServiceStyles = () => {

}
export default ServiceStyles