// styles
export const videoContainer = {
    margin: '0 10px',
    display: 'flex',
    background: "rgb(252, 252, 252)"
}
export const video = {
    maxWidth: '636px',
    height: '358px',
    objectFit: 'cover'
}
export const introText = {
    margin: 'auto 10px'
}
const VideoStyles = () => {

}
export default VideoStyles