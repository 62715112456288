import React from "react"
import * as separatorStyles from "./separatorStyle";

export const Separator = ({name}) => {

    return (
        <div id={name} style={separatorStyles.separator}>

        </div>
    )
}