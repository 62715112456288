import React from "react"
import {useTranslation} from "react-i18next";
import Slide from 'react-reveal/Slide';
import * as serviceStyles from "./serviceStyle";
import RiskEffectsImg from '../../images/service/riskeffect.jpg';
import OutsourcingImg from '../../images/service/outsourcing.jpg';
import ConsultingImg from '../../images/service/consulting.jpg';
import ArtificialImg from '../../images/service/artificial.jpg';

export const Service = () => {
    const {t} = useTranslation();
    return (
        <div className={'service-component'} style={serviceStyles.service}>
            <div className={'service'} style={serviceStyles.serviceBlock}>
                <Slide left big>
                    <div className={'service-text'} style={serviceStyles.serviceTextLeft}>
                        <h4> {t('app.block.service.riskEffects.title')} </h4>
                        <p className={'m-0'}> {t('app.block.service.riskEffects.text')} </p>
                    </div>
                </Slide>
                <Slide right big>
                    <img className={'service-img'} style={serviceStyles.serviceImage} src={RiskEffectsImg} alt={'RiskEffects'}/>
                </Slide>
            </div>

            <div className={'service'} style={serviceStyles.serviceBlock}>
                <Slide left big>
                    <img className={'service-img'} style={serviceStyles.serviceImage2} src={OutsourcingImg} alt={'Outsourcing'}/>
                </Slide>
                <Slide right big>
                    <div className={'service-text'} style={serviceStyles.serviceTextRight}>
                        <h4> {t('app.block.service.outsourcing.title')} </h4>
                        <p className={'m-0'}> {t('app.block.service.outsourcing.text')} </p>
                    </div>
                </Slide>
            </div>

            <div className={'service'} style={serviceStyles.serviceBlock}>
                <Slide left big>
                    <div className={'service-text'} style={serviceStyles.serviceTextLeft}>
                        <h4> {t('app.block.service.consulting.title')} </h4>
                        <div className={'m-0'}>
                            <p className={'m-0'}> {t('app.block.service.consulting.text')} </p>
                        </div>
                    </div>
                </Slide>
                <Slide right big>
                    <img className={'service-img'} style={serviceStyles.serviceImage} src={ConsultingImg} alt={'test'}/>
                </Slide>
            </div>

            <div className={'service'} style={serviceStyles.serviceBlock}>
                <Slide left big>
                    <img className={'service-img'} style={serviceStyles.serviceImage2} src={ArtificialImg} alt={'Artificial Intelligence'}/>
                </Slide>
                <Slide right big>
                    <div className={'service-text'} style={serviceStyles.serviceTextRight}>
                        <h4> {t('app.block.service.artificial.title')} </h4>
                        <p className={'m-0'}> {t('app.block.service.artificial.text')} </p>
                    </div>
                </Slide>
            </div>
        </div>
    )
}