import React from "react"
import {useTranslation} from "react-i18next";
import * as referenceStyles from "./referenceStyle";
import ReferenceImg from '../../../images/references/reference.jpg';


export const Reference = () => {
    const {t} = useTranslation();
    return (
        <div style={referenceStyles.referenceBlock}>
            <p style={referenceStyles.referenceEnlarged}> {t('app.block.reference')} </p>
            <p> {t('app.block.references.first.text')} </p>
            <img style={referenceStyles.referenceImage} src={ReferenceImg} alt={'References'}/>
            <p style={referenceStyles.referenceEnlarged}> {t('app.block.reference.name')} </p>
            <p> {t('app.block.references.second.text')} </p>
        </div>
    )
}