import React from "react"
import * as mainStyle from "../styles/styles";
import Container from "react-bootstrap/Container";
import 'bootstrap/dist/css/bootstrap.min.css';
import BackToTop from 'react-back-to-top-button';
import "../styles/global.css";
import "../styles/toggler.css";
import {Service} from "../components/Service/service"
import {Team} from "../components/AboutUs/Team/team"
import {Reference} from "../components/AboutUs/References/references"
import {Video} from "../components/Video/video"
import {Separator} from "../components/Separator/separator"
import {QesNavbar} from "../components/Navbar/navbar"
import {Footer} from "../components/Footer/footer"
import Logo from '../images/logo/logo2.svg';
import {Helmet} from "react-helmet";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function Index() {

    return (
        <div>
            <Helmet>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
                <script type="text/javascript"
                        src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>
                <link rel="stylesheet"
                      href="https://fonts.googleapis.com/css?family=open-sans:300,400,500,600&display=swap"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600"/>
                <link rel="icon" type="image/x-icon" href={Logo}/>
            </Helmet>
            <main style={mainStyle.pageStyles}>
                <title>Quant Edge Solutions | Home</title>
                <QesNavbar/>
                <BackToTop
                    showAt={50}
                    speed={1500}
                    easing="easeInOutQuint"
                >
                    <ArrowUpwardIcon sx={{color: '#203B72'}}/>
                </BackToTop>
            </main>
            <Container>
                <Video/>
                <Separator name={'services'}/>
                <Service/>
                <Separator name={'team'}/>
                <Team/>
                <Separator name={'references'}/>
                <Reference/>
                <Footer/>
            </Container>
        </div>
    )
}

